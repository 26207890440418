import React from "react";
// @ts-ignore
import {Container, Row, Col} from "shards-react";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import MainFooter from "../components/layout/MainFooter";

interface DefaultLayoutProps {
    noNavbar: boolean,
    noFooter: boolean
}

class DefaultLayout extends React.Component<DefaultLayoutProps> {
    static defaultProps = {
        noNavbar: false,
        noFooter: false
    };
    render() {
        return <Container fluid>
            <Row>
                <MainSidebar/>
                <Col
                    className="main-content p-0"
                    lg={{size: 10, offset: 2}}
                    md={{size: 9, offset: 3}}
                    sm="12"
                    tag="main"
                >
                    {!this.props.noNavbar && <MainNavbar/>}
                    {this.props.children}
                    {!this.props.noFooter && <MainFooter/>}
                </Col>
            </Row>
        </Container>;
    }
}

export default DefaultLayout;
