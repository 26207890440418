import React from "react";
// @ts-ignore
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    FormSelect,
    ListGroup,
    ListGroupItem,
    Row
    // @ts-ignore
} from "shards-react";
import "../../assets/range-date-picker.css";
import PageTitle from "../../components/common/PageTitle";
import SmallStats from "../../components/common/SmallStats";
import {get_overview, get_schedule_overview} from "../../redux/apis/userApis";
import Loader from "../../components/Loader";
import {ScheduleOverview} from "../UserOverview/UserOverview";

import Chart from "../../utils/chart";
import ScheduleOverviewComponent from "../../components/common/ScheduleOverview";
import {
    STRING_AUTO_COMMENT,
    STRING_AUTO_DM,
    STRING_AUTO_FOLLOW,
    STRING_AUTO_LIKE,
    STRING_AUTO_UN_FOLLOW,
    STRING_AUTO_WELCOME_DM
} from "../../redux/types";

interface OverviewProps {
}

interface UserOverviewState {
    username: string,
    smallStats: any[],
    schedule_overview: ScheduleOverview | undefined,
    schedule_overview_loading: boolean,
    schedule_overview_filter: string
    schedule_overview_auto_like_filter: string
    schedule_overview_auto_comment_filter: string
    schedule_overview_auto_follow_filter: string
    schedule_overview_auto_dm_filter: string
    schedule_overview_auto_welcome_dm_filter: string
    schedule_overview_auto_unfollow_filter: string
}

class Overview extends React.Component<OverviewProps, UserOverviewState> {

    auto_like_canvas_ref: any;
    auto_comment_canvas_ref: any;
    auto_follow_canvas_ref: any;
    auto_welcome_dm_canvas_ref: any;
    auto_dm_canvas_ref: any;
    auto_unfollow_canvas_ref: any;

    constructor(props: OverviewProps) {
        super(props);
        let username = window.location.pathname.split("/");
        this.state = {
            username: username[2],
            smallStats: [],
            schedule_overview: undefined,
            schedule_overview_loading: true,
            schedule_overview_filter: "7",
            schedule_overview_auto_like_filter: "7",
            schedule_overview_auto_comment_filter: "7",
            schedule_overview_auto_follow_filter: "7",
            schedule_overview_auto_dm_filter: "7",
            schedule_overview_auto_welcome_dm_filter: "7",
            schedule_overview_auto_unfollow_filter: "7",
        };

        this.auto_like_canvas_ref = React.createRef();
        this.auto_comment_canvas_ref = React.createRef();
        this.auto_follow_canvas_ref = React.createRef();
        this.auto_welcome_dm_canvas_ref = React.createRef();
        this.auto_dm_canvas_ref = React.createRef();
        this.auto_unfollow_canvas_ref = React.createRef();
    }

    handleStartDateChange = (value: any) => {
        this.setState({
            ...this.state,
            ...{startDate: new Date(value)}
        });
    };

    load_over_view = () => {
        get_overview(this.state.schedule_overview_filter).then(data => {
            if (!data.error) {
                this.setState({
                    schedule_overview: data.overview,
                    schedule_overview_loading: false
                });
            }
        });
    };
    load_schedule_over_view = (schedule_type: string, filter_time: string) => {
        console.log(schedule_type);
        get_schedule_overview(filter_time, schedule_type).then(data => {
            if (!data.error) {

                if (schedule_type === STRING_AUTO_LIKE) {
                    const chartConfig: any = {
                        type: "pie",
                        data: {
                            datasets: [
                                {
                                    hoverBorderColor: "#ffffff",
                                    data: [data.result.auto_like.pending, data.result.auto_like.done, data.result.auto_like.failed],
                                    backgroundColor: [
                                        "rgba(0,123,255,0.9)",
                                        "rgba(0,123,255,0.5)",
                                        "rgba(0,123,255,0.3)"
                                    ]
                                }
                            ],
                            labels: ["Pending", "Done", "Failed"]
                        },
                        options: {
                            ...{
                                legend: {
                                    position: "bottom",
                                    labels: {
                                        padding: 25,
                                        boxWidth: 20
                                    }
                                },
                                cutoutPercentage: 0,
                                tooltips: {
                                    custom: false,
                                    mode: "index",
                                    position: "nearest"
                                }
                            },
                        }
                    };
                    new Chart(this.auto_like_canvas_ref.current, chartConfig);
                } else if (schedule_type === STRING_AUTO_COMMENT) {
                    const chartConfig: any = {
                        type: "pie",
                        data: {
                            datasets: [
                                {
                                    hoverBorderColor: "#ffffff",
                                    data: [data.result.auto_comment.pending, data.result.auto_comment.done, data.result.auto_comment.failed],
                                    backgroundColor: [
                                        "rgba(0,123,255,0.9)",
                                        "rgba(0,123,255,0.5)",
                                        "rgba(0,123,255,0.3)"
                                    ]
                                }
                            ],
                            labels: ["Pending", "Done", "Failed"]
                        },
                        options: {
                            ...{
                                legend: {
                                    position: "bottom",
                                    labels: {
                                        padding: 25,
                                        boxWidth: 20
                                    }
                                },
                                cutoutPercentage: 0,
                                tooltips: {
                                    custom: false,
                                    mode: "index",
                                    position: "nearest"
                                }
                            },
                        }
                    };
                    new Chart(this.auto_comment_canvas_ref.current, chartConfig);
                } else if (schedule_type === STRING_AUTO_FOLLOW) {
                    console.log(data);
                    const chartConfig: any = {
                        type: "pie",
                        data: {
                            datasets: [
                                {
                                    hoverBorderColor: "#ffffff",
                                    data: [data.result.auto_follow.pending, data.result.auto_follow.done, data.result.auto_follow.failed],
                                    backgroundColor: [
                                        "rgba(0,123,255,0.9)",
                                        "rgba(0,123,255,0.5)",
                                        "rgba(0,123,255,0.3)"
                                    ]
                                }
                            ],
                            labels: ["Pending", "Done", "Failed"]
                        },
                        options: {
                            ...{
                                legend: {
                                    position: "bottom",
                                    labels: {
                                        padding: 25,
                                        boxWidth: 20
                                    }
                                },
                                cutoutPercentage: 0,
                                tooltips: {
                                    custom: false,
                                    mode: "index",
                                    position: "nearest"
                                }
                            },
                        }
                    };
                    new Chart(this.auto_follow_canvas_ref.current, chartConfig);
                }else if (schedule_type === STRING_AUTO_DM) {
                    console.log(data);
                    const chartConfig: any = {
                        type: "pie",
                        data: {
                            datasets: [
                                {
                                    hoverBorderColor: "#ffffff",
                                    data: [data.result.auto_dm.pending, data.result.auto_dm.done, data.result.auto_dm.failed],
                                    backgroundColor: [
                                        "rgba(0,123,255,0.9)",
                                        "rgba(0,123,255,0.5)",
                                        "rgba(0,123,255,0.3)"
                                    ]
                                }
                            ],
                            labels: ["Pending", "Done", "Failed"]
                        },
                        options: {
                            ...{
                                legend: {
                                    position: "bottom",
                                    labels: {
                                        padding: 25,
                                        boxWidth: 20
                                    }
                                },
                                cutoutPercentage: 0,
                                tooltips: {
                                    custom: false,
                                    mode: "index",
                                    position: "nearest"
                                }
                            },
                        }
                    };
                    new Chart(this.auto_dm_canvas_ref.current, chartConfig);
                } else if (schedule_type === STRING_AUTO_WELCOME_DM) {

                    const chartConfig: any = {
                        type: "pie",
                        data: {
                            datasets: [
                                {
                                    hoverBorderColor: "#ffffff",
                                    data: [data.result.auto_welcome_dm.pending, data.result.auto_welcome_dm.done, data.result.auto_welcome_dm.failed],
                                    backgroundColor: [
                                        "rgba(0,123,255,0.9)",
                                        "rgba(0,123,255,0.5)",
                                        "rgba(0,123,255,0.3)"
                                    ]
                                }
                            ],
                            labels: ["Pending", "Done", "Failed"]
                        },
                        options: {
                            ...{
                                legend: {
                                    position: "bottom",
                                    labels: {
                                        padding: 25,
                                        boxWidth: 20
                                    }
                                },
                                cutoutPercentage: 0,
                                tooltips: {
                                    custom: false,
                                    mode: "index",
                                    position: "nearest"
                                }
                            },
                        }
                    };
                    new Chart(this.auto_welcome_dm_canvas_ref.current, chartConfig);
                } else if (schedule_type === STRING_AUTO_UN_FOLLOW) {
                    const chartConfig: any = {
                        type: "pie",
                        data: {
                            datasets: [
                                {
                                    hoverBorderColor: "#ffffff",
                                    data: [data.result.auto_unfollow.pending, data.result.auto_unfollow.done, data.result.auto_unfollow.failed],
                                    backgroundColor: [
                                        "rgba(0,123,255,0.9)",
                                        "rgba(0,123,255,0.5)",
                                        "rgba(0,123,255,0.3)"
                                    ]
                                }
                            ],
                            labels: ["Pending", "Done", "Failed"]
                        },
                        options: {
                            ...{
                                legend: {
                                    position: "bottom",
                                    labels: {
                                        padding: 25,
                                        boxWidth: 20
                                    }
                                },
                                cutoutPercentage: 0,
                                tooltips: {
                                    custom: false,
                                    mode: "index",
                                    position: "nearest"
                                }
                            },
                        }
                    };
                    new Chart(this.auto_unfollow_canvas_ref.current, chartConfig);
                }
            }
        });
    };

    over_view_on_filter = (e: any) => {
        this.setState({
            schedule_overview_filter: e.target.value,
            schedule_overview_loading: true
        }, this.load_over_view);
    };


    componentDidMount(): void {
        this.load_over_view();

        this.load_schedule_over_view(STRING_AUTO_LIKE, this.state.schedule_overview_auto_like_filter);
        this.load_schedule_over_view(STRING_AUTO_COMMENT, this.state.schedule_overview_auto_comment_filter);
        this.load_schedule_over_view(STRING_AUTO_FOLLOW, this.state.schedule_overview_auto_follow_filter);
        this.load_schedule_over_view(STRING_AUTO_DM, this.state.schedule_overview_auto_dm_filter);
        this.load_schedule_over_view(STRING_AUTO_WELCOME_DM, this.state.schedule_overview_auto_welcome_dm_filter);
        this.load_schedule_over_view(STRING_AUTO_UN_FOLLOW, this.state.schedule_overview_auto_unfollow_filter);
    }

    render() {
        return <Container fluid className="main-content-container px-4">

            <Row noGutters className="page-header py-4">
                <PageTitle title={this.state.username} subtitle="Dashboard" className="text-sm-left mb-3"/>
            </Row>
            <Row>
                {this.state.smallStats.map((stats, idx) => (
                    <Col className="col-lg mb-4" key={idx} md={"6"} sm={"6"}>
                        <SmallStats
                            variation="1"
                            label={stats.label}
                            value={stats.value}
                            percentage={stats.percentage}
                            increase={stats.increase}
                        />
                    </Col>
                ))}
                <Col lg="3" md="12" sm="12" className="mb-4">
                    <Card small>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Overview</h6>
                            <div className="block-handle"/>
                        </CardHeader>
                        {this.state.schedule_overview_loading ? <Loader/> : <CardBody className="p-0">
                            <ListGroup small flush className="list-group-small">
                                <ListGroupItem className="d-flex px-3">
                                    <span className="text-semibold text-fiord-blue">Auto like</span>
                                    <span className="ml-auto text-right text-semibold text-reagent-gray">
              {this.state.schedule_overview ? this.state.schedule_overview.auto_like_count : 0}
            </span>
                                </ListGroupItem>
                                <ListGroupItem className="d-flex px-3">
                                    <span className="text-semibold text-fiord-blue">Auto comment</span>
                                    <span className="ml-auto text-right text-semibold text-reagent-gray">
              {this.state.schedule_overview ? this.state.schedule_overview.auto_comment_count : 0}
            </span>
                                </ListGroupItem>
                                <ListGroupItem className="d-flex px-3">
                                    <span className="text-semibold text-fiord-blue">Auto follow</span>
                                    <span className="ml-auto text-right text-semibold text-reagent-gray">
              {this.state.schedule_overview ? this.state.schedule_overview.auto_follow_count : 0}
            </span>
                                </ListGroupItem>
                                <ListGroupItem className="d-flex px-3">
                                    <span className="text-semibold text-fiord-blue">Auto dm</span>
                                    <span className="ml-auto text-right text-semibold text-reagent-gray">
              {this.state.schedule_overview ? this.state.schedule_overview.auto_dm_count : 0}
            </span>
                                </ListGroupItem>
                                <ListGroupItem className="d-flex px-3">
                                    <span className="text-semibold text-fiord-blue">Auto post</span>
                                    <span className="ml-auto text-right text-semibold text-reagent-gray">
              {this.state.schedule_overview ? this.state.schedule_overview.auto_post_count : 0}
            </span>
                                </ListGroupItem>
                                <ListGroupItem className="d-flex px-3">
                                    <span className="text-semibold text-fiord-blue">Auto UnFollow</span>
                                    <span className="ml-auto text-right text-semibold text-reagent-gray">
              {this.state.schedule_overview ? this.state.schedule_overview.auto_unfollow_count : 0}
            </span>
                                </ListGroupItem>
                                <ListGroupItem className="d-flex px-3">
                                    <span className="text-semibold text-fiord-blue">Auto Welcome DM</span>
                                    <span className="ml-auto text-right text-semibold text-reagent-gray">
              {this.state.schedule_overview ? this.state.schedule_overview.auto_welcome_dm_count : 0}
            </span>
                                </ListGroupItem>
                            </ListGroup>
                        </CardBody>}
                        {this.state.schedule_overview_loading ? <div/> : <CardFooter className="border-top">
                            <Row>
                                <Col>
                                    <FormSelect
                                        size="sm"
                                        value={this.state.schedule_overview_filter}
                                        style={{maxWidth: "130px"}}
                                        onChange={this.over_view_on_filter}
                                    >
                                        <option value="1">Last 1 day</option>
                                        <option value="2">Last 2 day</option>
                                        <option value="3">Last 3 day</option>
                                        <option value="7">Last 7 day</option>
                                        <option value="30">Last 30 day</option>
                                        <option value="365">Last 365 day</option>
                                    </FormSelect>
                                </Col>
                            </Row>
                        </CardFooter>}
                    </Card>
                </Col>
                <Col lg="12" md="12" sm="12" className="mb-4"/>
                <Col lg="3" md="12" sm="12" className="mb-4">
                    <ScheduleOverviewComponent title={"Auto Like"}

                                               filter_value={this.state.schedule_overview_auto_like_filter}
                                               canvas_ref={this.auto_like_canvas_ref}
                                               onFormChange={(e) => {
                                                   this.setState({
                                                       schedule_overview_auto_like_filter: e.target.value,
                                                   }, () => {
                                                       this.load_schedule_over_view(STRING_AUTO_LIKE, this.state.schedule_overview_auto_like_filter)
                                                   });
                                               }
                                               }

                    />
                </Col>
                <Col lg="3" md="12" sm="12" className="mb-4">
                    <ScheduleOverviewComponent title={"Auto Comment"}

                                               filter_value={this.state.schedule_overview_auto_comment_filter}
                                               canvas_ref={this.auto_comment_canvas_ref}
                                               onFormChange={(e) => {
                                                   this.setState({
                                                       schedule_overview_auto_comment_filter: e.target.value,
                                                   }, () => {
                                                       this.load_schedule_over_view(STRING_AUTO_COMMENT, this.state.schedule_overview_auto_comment_filter)
                                                   });
                                               }}
                    />
                </Col>
                <Col lg="3" md="12" sm="12" className="mb-4">
                    <ScheduleOverviewComponent title={"Auto Follow"}
                                               filter_value={this.state.schedule_overview_auto_follow_filter}
                                               canvas_ref={this.auto_follow_canvas_ref}
                                               onFormChange={(e) => {
                                                   this.setState({
                                                       schedule_overview_auto_follow_filter: e.target.value,
                                                   }, () => {
                                                       this.load_schedule_over_view(STRING_AUTO_FOLLOW, this.state.schedule_overview_auto_follow_filter)
                                                   });
                                               }}
                    />
                </Col>
                <Col lg="3" md="12" sm="12" className="mb-4">
                    <ScheduleOverviewComponent title={"Auto DM"}

                                               filter_value={this.state.schedule_overview_auto_dm_filter}
                                               canvas_ref={this.auto_dm_canvas_ref}
                                               onFormChange={(e) => {
                                                   this.setState({
                                                       schedule_overview_auto_dm_filter: e.target.value,
                                                   }, () => {
                                                       this.load_schedule_over_view(STRING_AUTO_DM, this.state.schedule_overview_auto_dm_filter)
                                                   });
                                               }}
                    />
                </Col>
                <Col lg="3" md="12" sm="12" className="mb-4">
                    <ScheduleOverviewComponent title={"Auto Welcome"}

                                               filter_value={this.state.schedule_overview_auto_welcome_dm_filter}
                                               canvas_ref={this.auto_welcome_dm_canvas_ref}
                                               onFormChange={(e) => {
                                                   this.setState({
                                                       schedule_overview_auto_welcome_dm_filter: e.target.value,
                                                   }, () => {
                                                       this.load_schedule_over_view(STRING_AUTO_WELCOME_DM, this.state.schedule_overview_auto_welcome_dm_filter)
                                                   });
                                               }}
                    />
                </Col>
                <Col lg="3" md="12" sm="12" className="mb-4">
                    <ScheduleOverviewComponent title={"Auto UnFollow"}

                                               filter_value={this.state.schedule_overview_auto_unfollow_filter}
                                               canvas_ref={this.auto_unfollow_canvas_ref}
                                               onFormChange={(e) => {
                                                   this.setState({
                                                       schedule_overview_auto_unfollow_filter: e.target.value,
                                                   }, () => {
                                                       this.load_schedule_over_view(STRING_AUTO_UN_FOLLOW, this.state.schedule_overview_auto_unfollow_filter)
                                                   });
                                               }}
                    />
                </Col>
            </Row>
        </Container>;
    }
}


export default Overview;
