import React from 'react';
import ReactDOM from 'react-dom';
import {createStore} from 'redux';
import {storeReducer} from './redux/reducers';
import * as types from './redux/types'
import {Provider} from 'react-redux';
import AppController from './controllers/AppController';
import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
const store = createStore(storeReducer, types.getInitialStoreState());

ReactDOM.render(<Provider store={store}><AppController/></Provider>, document.getElementById('root'));
