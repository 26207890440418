import React from "react";
import {NavLink as RouteNavLink} from "react-router-dom";
// @ts-ignore
import {NavItem, NavLink} from "shards-react";


interface SidebarNavItemProps {
    item: any
}

class SidebarNavItem extends React.Component<SidebarNavItemProps> {
    static defaultProps = {
        item: [],
    };

    render() {
        return <NavItem>
            <NavLink tag={RouteNavLink} to={this.props.item.to}>
                {this.props.item.htmlBefore && (
                    <div
                        className="d-inline-block item-icon-wrapper"
                        dangerouslySetInnerHTML={{__html: this.props.item.htmlBefore}}
                    />
                )}
                {this.props.item.title && <span>{this.props.item.title}</span>}
                {this.props.item.htmlAfter && (
                    <div
                        className="d-inline-block item-icon-wrapper"
                        dangerouslySetInnerHTML={{__html: this.props.item.htmlAfter}}
                    />
                )}
            </NavLink>
        </NavItem>;
    }
}

export default SidebarNavItem;
