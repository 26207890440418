import * as React from 'react';
import {connect} from 'react-redux';
import {Dispatch} from "redux";

import * as actions from '../redux/actions';
import * as loginApis from '../redux/apis/userApis';
import LoginPage from "../pages/LoginPage";

interface Props {
    dispatch: Dispatch<actions.iStoreAction>
}

interface State {
    loading: boolean,
    error_message: string
}


class LoginController extends React.Component<Props, State> {
    state: State = {
        loading: false,
        error_message: ""
    };

    verifyLogin = () => {
        this.setState({loading: true});
        loginApis.verify().then(response => this.onLoginResponse(response, true));
    };

    onClickLogin = (email: string, password: string) => {
        this.setState({loading: true, error_message: ""});
        loginApis.login(email, password).then(response => this.onLoginResponse(response));
    };

    onLoginResponse = (response: loginApis.iLoginResponse, silent: boolean = false) => {
        if (!response.error) {
            this.props.dispatch(actions.setLoggedInUser({
                name: response.name,
                email: response.email
            }));
        } else {
            this.setState({
                loading: false,
                error_message: silent ? "" : response.message
            });
        }
    };

    render() {
        return <LoginPage
            loading={this.state.loading}
            validationError={this.state.error_message}
            onLogin={this.onClickLogin}/>
    }

    componentDidMount(): void {
        this.verifyLogin();
    }
}

function mapStateToProps() {
    return {}
}

function mapDispatchToProps(dispatch: Dispatch<actions.iStoreAction>) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginController);
