import * as constants from '../constants';
import * as types from '../types';

export interface iSetLoggedInUser {
    type: constants.TYPE_SET_LOGGED_IN_USER,
    user: types.iLoggedInUser
}

export interface iRemoveLoggedInUser {
    type: constants.TYPE_REMOVE_LOGGED_IN_USER,
}


export type iStoreAction = iSetLoggedInUser | iRemoveLoggedInUser;

export const setLoggedInUser = (user: types.iLoggedInUser): iStoreAction => {
    return {
        type: constants.SET_LOGGED_IN_USER,
        user: user
    }
};

export const removeLoggedInUser = (): iStoreAction => {
    return {
        type: constants.REMOVE_LOGGED_IN_USER
    }
};