import React from "react";
import classNames from "classnames";
// @ts-ignore
import {Col} from "shards-react";

interface PageTitleProps {
    title: string,
    subtitle: string
    className:string
}

class PageTitle extends React.Component<PageTitleProps> {
    render() {
        const classes = classNames(
            this.props.className,
            "text-center",
            "text-md-left",
            "mb-sm-0"
        );

        return (
            <Col xs="12" sm="4" className={classes} >
                <span className="text-uppercase page-subtitle">{this.props.subtitle}</span>
                <h3 className="page-title">{this.props.title}</h3>
            </Col>
        )
    }
}


export default PageTitle;
