import React from "react";
// @ts-ignore
import {Card, CardBody, CardHeader, Col, Container, FormInput, InputGroup, Row} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import SmallStats from "../../components/common/SmallStats";
import moment from "moment";
import {Link} from "react-router-dom";
import {delete_user, get_all_users} from "../../redux/apis/userApis";
import {generate_map_link, get_unblock_time, is_big_date, is_user_blocked, User} from "../../redux/types";
import Loader from "../../components/Loader";


interface UserOverviewProps {
}

interface UserOverviewState {
    users: User[]
    users_filter: User[]
    users_filter_text: string
    blocked_users: User[],
    blocked_users_filter: User[],
    blocked_users_filter_text: string,
    smallStats: any[],
    dropdown: boolean,
    loading: boolean,
}

class UsersOverview extends React.Component<UserOverviewProps, UserOverviewState> {


    constructor(props: UserOverviewProps) {
        super(props);
        this.state = {
            users: [],
            blocked_users: [],
            blocked_users_filter_text: "",
            users_filter: [],
            blocked_users_filter: [],
            users_filter_text: "",
            smallStats: [],
            dropdown: false,
            loading: true,
        }
    }

    handle_get_users = () => {
        get_all_users().then(data => {
            if (data.error) {
                console.log(data.message);
                return;
            }

            if (data.users) {
                this.setState({
                    loading: false,
                    users: data.users,
                    users_filter: data.users
                });
                let blocked_users: User[] = [];
                let blocked_user_count = 0;
                let logged_in_user_count = 0;
                let active_user_count = 0;
                let user_failed_to_count = 0;
                for (let i = 0; i < data.users.length; i++) {
                    if (is_user_blocked(data.users[i].last_action_block_time)) {
                        blocked_users.push(data.users[i]);
                        blocked_user_count += 1;
                    }
                    if (data.users[i].is_logged_in) {
                        logged_in_user_count += 1;
                    } else {
                        user_failed_to_count += 1
                    }
                    if (data.users[i].is_running) {
                        active_user_count += 1;
                    }
                }
                this.setState({
                    blocked_users: blocked_users,
                    blocked_users_filter: blocked_users
                });
                let smallStats = [
                    {
                        label: "Active Now User",
                        value: active_user_count,
                        increase: true,
                    },
                    {
                        label: "Logged In",
                        value: logged_in_user_count,
                        increase: true,
                    },
                    {
                        label: "Blocked Users",
                        value: blocked_user_count,
                        increase: true,
                    },
                    {
                        label: "User Failed to Login",
                        value: user_failed_to_count,
                        increase: true,
                    }
                ];

                this.setState({
                    smallStats: smallStats
                })
            }
        });
    };

    componentDidMount(): void {
        this.handle_get_users();
    }

    user_filter_change = (e: any) => {
        let search = e.target.value.toLowerCase();
        this.setState({
            users_filter_text: search
        });
        let filter_users = [];
        for (let i = 0; i < this.state.users.length; i++) {
            if (search === "" || this.state.users[i].user_id.includes(search)) {
                filter_users.push(this.state.users[i]);
            }

        }
        this.setState({
            users_filter: filter_users
        });
    };
    block_filter_change = (e: any) => {
        let search = e.target.value.toLowerCase();
        this.setState({
            blocked_users_filter_text: search
        });
        let filter_users = [];
        for (let i = 0; i < this.state.blocked_users.length; i++) {
            if (search === "" || this.state.blocked_users[i].user_id.includes(search)) {
                filter_users.push(this.state.blocked_users[i]);
            }

        }
        this.setState({
            blocked_users_filter: filter_users
        });
    };
    toggle = () => {
        this.setState({
            dropdown: !this.state.dropdown
        })
    };
    handle_delete_user = (username: string) => {
        delete_user(username).then(() => {
            this.handle_get_users();
        });
    };

    render() {
        return <Container fluid className="main-content-container px-4">

            <Row noGutters className="page-header py-4">
                <PageTitle title="Users Overview" subtitle="Dashboard" className="text-sm-left mb-3"/>
            </Row>

            <Row>
                {this.state.smallStats.map((stats, idx) => (
                    <Col className="col-lg mb-4" key={idx} md={"6"} sm={"6"}>
                        <SmallStats
                            variation="1"
                            label={stats.label}
                            value={stats.value}
                            percentage={stats.percentage}
                            increase={stats.increase}
                        />
                    </Col>
                ))}
            </Row>
            <Row>
                <Col>
                    <Card small className="mb-4 overflow-scroll">
                        <CardHeader className="border-bottom">
                            <Row>
                                <Col><h6 className="m-0">All Users</h6></Col>
                                <Col className="w-100 col-lg mb-4" md={"1"} sm={"1"}>
                                    <InputGroup seamless className="mb-16">
                                        <FormInput placeholder="Search" value={this.state.users_filter_text}
                                                   onChange={this.user_filter_change}/>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0 pb-3">

                            <table className="table mb-0">
                                <thead className="bg-light">
                                <tr>
                                    <th scope="col" className="border-0">
                                        #
                                    </th>
                                    <th scope="col" className="border-0">
                                        Username
                                    </th>
                                    <th scope="col" className="border-0">
                                        AVD Device name
                                    </th>
                                    <th scope="col" className="border-0">
                                        http_proxy
                                    </th>
                                    <th scope="col" className="border-0">
                                        Logged in
                                    </th>
                                    <th scope="col" className="border-0">
                                        Location
                                    </th>
                                    <th scope="col" className="border-0">
                                        Next action time
                                    </th>
                                    <th scope="col" className="border-0">
                                        Blocked
                                    </th>
                                    <th scope="col" className="border-0">
                                        Running
                                    </th>
                                    <th scope="col" className="border-0">
                                        Delete
                                    </th>
                                </tr>
                                </thead>
                                {this.state.loading ? <tbody>
                                <tr>
                                    <td colSpan={9}><Loader/></td>
                                </tr>
                                </tbody> : <tbody>
                                {
                                    this.state.users_filter.map((user, index) => {
                                        return <tr key={index} color={"red"}>
                                            <td>{index + 1}</td>
                                            <td><Link to={"user/" + user.user_id}>{user.user_id}</Link></td>
                                            <td>{user.avd_device_name}</td>
                                            <td>{user.http_proxy}</td>
                                            <td>{user.is_logged_in ? "Yes" : "No"}</td>
                                            <td>{(user.lat === 0 || user.lng === 0) ?
                                                "-" : <a href={generate_map_link(user.lat, user.lng)} target={"_blank"}>click
                                                    to see</a>
                                            }</td>
                                            <td>{(!is_user_blocked(user.last_action_block_time) && user.is_logged_in && is_big_date(user.next_action_time))
                                                ?
                                                moment(user.next_action_time).fromNow(true)
                                                : "-"}</td>
                                            <td>{is_user_blocked(user.last_action_block_time) ? "yes" : "no"}</td>
                                            <td>{user.is_running ?
                                                <i className="material-icons" style={{color: "red"}}>done</i> :
                                                <i className="material-icons">close</i>}</td>
                                            <td>{<button disabled={user.is_logged_in}
                                                         onClick={() => {
                                                             this.handle_delete_user(user.user_id)
                                                         }}
                                                         className="btn btn-danger">Delete</button>}
                                            </td>
                                        </tr>;
                                    })
                                }
                                </tbody>}

                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card small className="mb-4 overflow-scroll">
                        <CardHeader className="bg-dark">

                            <Row>
                                <Col><h6 className="m-0 text-white">Blocked Users</h6></Col>
                                <Col className="w-100 col-lg mb-4" md={"1"} sm={"1"}>
                                    <InputGroup seamless className="mb-16">
                                        <FormInput placeholder="Search" value={this.state.blocked_users_filter_text}
                                                   onChange={this.block_filter_change}/>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="bg-dark p-0 pb-3">
                            <table className="table table-dark mb-0">

                                <thead className="thead-dark">
                                <tr>
                                    <th scope="col" className="border-0">
                                        #
                                    </th>
                                    <th scope="col" className="border-0">
                                        Username
                                    </th>
                                    <th scope="col" className="border-0">
                                        AVD Device name
                                    </th>
                                    <th scope="col" className="border-0">
                                        Http Proxy
                                    </th>
                                    <th scope="col" className="border-0">
                                        Location
                                    </th>
                                    <th scope="col" className="border-0">
                                        Unblock Time
                                    </th>
                                </tr>
                                </thead>
                                {this.state.loading ? <tbody>
                                <tr>
                                    <td colSpan={7}><Loader/></td>
                                </tr>
                                </tbody> : <tbody>
                                {
                                    this.state.blocked_users_filter.map((user, index) => {
                                        return <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td><Link to={"user/" + user.user_id}>{user.user_id}</Link></td>
                                            <td>{user.avd_device_name}</td>
                                            <td>{user.http_proxy}</td>
                                            <td>{(user.lat === 0 || user.lng === 0) ?
                                                "-" : <a href={generate_map_link(user.lat, user.lng)} target={"_blank"}>click
                                                    to see</a>
                                            }</td>
                                            <td>{moment(get_unblock_time(user.last_action_block_time)).fromNow(true)}</td>
                                        </tr>;
                                    })
                                }
                                </tbody>}

                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }
}


export default UsersOverview;
