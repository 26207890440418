import React from "react";
// @ts-ignore
import {Col} from "shards-react";

import classNames from "classnames";
import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarSearch from "./SidebarSearch";
import SidebarNavItems from "./SidebarNavItems";

interface MainSidebarProps {
    hideLogoText: boolean
}

interface MainSidebarState {
    menuVisible: boolean
    sidebarNavItems: []
}

class MainSidebar extends React.Component<MainSidebarProps, MainSidebarState> {
    static defaultProps = {
        hideLogoText: false
    };

    constructor(props: MainSidebarProps) {
        super(props);
        this.state = {
            menuVisible: false,
            sidebarNavItems: []
        };

    }

    onChange = () => {
        this.setState({
            ...this.state,
        });
    };

    render() {
        const classes = classNames(
            "main-sidebar",
            "px-0",
            "col-12",
            this.state.menuVisible && "open"
        );

        return (
            <Col
                tag="aside"
                className={classes}
                lg={{size: 2}}
                md={{size: 3}}
            >
                <SidebarMainNavbar hideLogoText={this.props.hideLogoText}/>
                <SidebarSearch/>
                <SidebarNavItems/>
            </Col>
        );
    }
}

export default MainSidebar;
