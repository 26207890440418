import * as React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {iLoggedInUser, iStoreState} from '../redux/types';
import {connect} from 'react-redux';

import ScrollController from "./ScrollController";
import LoginController from "./LoginController";
import UsersOverview from "../pages/UsersOverView";
import UserOverview from "../pages/UserOverview";
import {DefaultLayout} from "../layouts";
import Overview from "../pages/Overview";

interface Props {
    loggedInUser: iLoggedInUser | undefined
}

interface State {

}

class AppController extends React.Component<Props, State> {
    render() {
        if (!this.props.loggedInUser) return <LoginController/>;
        return <Router>
            <ScrollController>
                <Switch>
                    <Route exact path="/overview" component={() => {
                        return <DefaultLayout>
                            <Overview />
                        </DefaultLayout>
                    }}/>
                    <Route exact path="/users" component={() => {
                        return <DefaultLayout>
                            <UsersOverview/>
                        </DefaultLayout>
                    }}/>
                    <Route exact path="/" component={() => <Redirect to="/users" />}/>
                    <Route exact path="/user/:id" component={() => {
                        return <DefaultLayout>
                            <UserOverview/>
                        </DefaultLayout>
                    }}/>
                </Switch>
            </ScrollController>
        </Router>
    }
}

function mapStateToProps({loggedInUser}: iStoreState) {
    return {loggedInUser: loggedInUser}
}

export default connect(mapStateToProps)(AppController);
