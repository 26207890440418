import moment from "moment";
import {Logger, ScheduleLogger, ScheduleOverview, ScheduleOverview2} from "../../pages/UserOverview/UserOverview";
import {SERVER_ADDRESS} from "../constants";

export interface BasicResponse {
    error: boolean,
    message: string
}

export interface IUsersData extends BasicResponse {
    users?: User[]
}

export interface IUserData extends BasicResponse {
    user?: User
}

export interface IUserOverview extends BasicResponse {
    overview?: ScheduleOverview
}
export interface IScheduleOverview extends BasicResponse {
    result: ScheduleOverview2
}

export interface IUserLog extends BasicResponse {
    logs?: Logger[]
}

export interface IUserScheduleLog extends BasicResponse {
    logs?: ScheduleLogger[]
}

export function is_user_blocked(date: string) {
    let date_object = moment(date).toDate();
    let d = new Date();
    d.setDate(d.getDate() - 2);
    return d < date_object;
}

export function is_big_date(date: string) {
    let date_object = moment(date).toDate();
    let d = new Date();
    return d < date_object;
}

export function get_unblock_time(date: string) {
    let date_object = moment(date).toDate();
    date_object.setDate(date_object.getDate() + 2);
    return date_object;
}

export const STRING_AUTO_LOGIN = "1";
export const STRING_AUTO_LIKE = "2";
export const STRING_AUTO_COMMENT = "3";
export const STRING_AUTO_FOLLOW = "4";
export const STRING_AUTO_DM = "5";
export const STRING_AUTO_WELCOME_DM = "6";
export const STRING_AUTO_POST = "7";
export const STRING_AUTO_UN_FOLLOW = "8";

const AUTO_LOGIN = 1;
const AUTO_LIKE = 2;
const AUTO_COMMENT = 3;
const AUTO_FOLLOW = 4;
const AUTO_DM = 5;
const AUTO_WELCOME_DM = 6;
const AUTO_POST = 7;
const AUTO_UN_FOLLOW = 8;

const TOP_SEARCH = 0;
const ACCOUNTS_SEARCH = 1;
const TAG_SEARCH = 2;
const PLACE_SEARCH = 3;

export function get_schedule_type(schedule_type: number) {
    switch (schedule_type) {
        case AUTO_LOGIN:
            return "AUTO LOGIN";
        case AUTO_LIKE:
            return "AUTO LIKE";
        case AUTO_COMMENT:
            return "AUTO COMMENT";
        case AUTO_FOLLOW:
            return "AUTO FOLLOW";
        case AUTO_DM:
            return "AUTO DM";
        case AUTO_WELCOME_DM:
            return "AUTO WELCOME DM";
        case AUTO_POST:
            return "AUTO POST";
        case AUTO_UN_FOLLOW:
            return "AUTO UNFOLLOW";
        default:
            return "Unknown";
    }
}

export function get_trigger_type(trigger_type: number) {
    switch (trigger_type) {
        case TOP_SEARCH:
            return "TOP SEARCH";
        case ACCOUNTS_SEARCH:
            return "ACCOUNTS SEARCH";
        case TAG_SEARCH:
            return "TAG SEARCH";
        case PLACE_SEARCH:
            return "PLACE SEARCH";
        default:
            return "Unknown";
    }
}

export function generate_screen_shot_link(screen_shot_id: string) {
    return SERVER_ADDRESS + "/screen-shot/" + screen_shot_id
}

export function generate_map_link(lat: number, lng: number) {
    return "https://maps.google.com/?q=" + lat + "," + lng
}

export interface User {
    avd_device_name?: string,
    http_proxy?: string,
    is_logged_in: boolean,
    is_running: boolean,
    last_action_block_time: string,
    lat: number,
    lng: number,
    next_action_time: string,
    user_id: string,

    is_user_blocked(): boolean
}

export interface iApiBasicResponse {
    error: boolean;
    message: string;
    auth_failure?: boolean;
}

export interface iLoggedInUser {
    name: string;
    email: string;
}

export interface iStoreState {
    loggedInUser: iLoggedInUser | undefined;

    languageName: string;
    enthusiasmLevel: number;
}

export const getInitialStoreState = (): iStoreState => {
    return {
        loggedInUser: undefined,
        languageName: "Java",
        enthusiasmLevel: 123
    }
};