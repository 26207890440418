import * as React from 'react';
import {ChangeEvent} from 'react';
import "../../styles.css"
import Loader from "../../components/Loader";
// @ts-ignore
import {Row, Col, Card, CardHeader, CardBody, Button, Form, FormGroup, FormInput, FormTextarea} from "shards-react";

interface LoginPageProps {
    validationError?: string,
    loginSuccess?: string, /* Not probably required */
    loading: boolean

    onLogin(email: string, password: string): void
}

interface LoginPageStates {
    email: string,
    password: string
}

class LoginPage extends React.Component<LoginPageProps, LoginPageStates> {

    constructor(props: LoginPageProps) {
        super(props);
        this.state = {
            email: "",
            password: ""
        };

    }

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.onLogin(this.state.email, this.state.password);
    };

    handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({email: event.target.value});
    };

    handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({password: event.target.value});
    };


    public render() {
        let validation_error = this.props.validationError ?
            <div className="">
                {this.props.validationError}
            </div> : "";

        let loginSuccess = this.props.loginSuccess ? <div className="">
            {this.props.loginSuccess}
        </div> : "";

        return <Row>
            <Col lg="3" md="3" sm="12" className="mt-auto offset-lg-4 offset-md-4 offset-sm-0 ">
                <Card small className="h-100 mt-5 ">
                    {/* Card Header */}
                    <CardHeader className="border-bottom">
                        <h6 className="m-0">{"Insta-bot admin panel"}</h6>
                    </CardHeader>

                    <CardBody className="d-flex flex-column">
                        <Form className="quick-post-form" onSubmit={this.handleSubmit}>
                            {/* Title */}
                            <FormGroup>
                                <FormInput type="email" name="email"
                                           required={true}
                                           value={this.state.email}
                                           onChange={this.handleEmailChange}
                                           placeholder="Email Address"/>
                            </FormGroup>

                            {/* Body */}
                            <FormGroup>
                                <FormInput type="password" name="Password"
                                           required={true}
                                           value={this.state.password}
                                           onChange={this.handlePasswordChange}
                                           placeholder="Password"/>
                            </FormGroup>
                            {
                                this.props.loading
                                    ? <Loader/>
                                    : <FormGroup className="mb-0">
                                        <Button theme="accent" type="submit">
                                            Login
                                        </Button>
                                    </FormGroup>
                            }
                            {validation_error}
                            {loginSuccess}
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </Row>;
        //
        // return <div style={{backgroundColor: "#fff", minHeight: "100vh"}}>
        //     <div className="container pdt-40" style={{width: "100%"}}>
        //         <form onSubmit={this.handleSubmit} className={"cmxform form-horizontal form-signin"}>
        //
        //             <h2 className="form-signin-heading">Insta-Bot Admin panel</h2>
        //             <div className="login-wrap">
        //
        //                 <div className="user-login-info">
        //                     <div className="input-container">
        //
        //                         <i className="fa fa-envelope icon"/>
        //                         <input className="form-control valid" id="cemail" type="email" name="email"
        //                                required={true}
        //                                value={this.state.email}
        //                                onChange={this.handleEmailChange}
        //                                placeholder="Email Address"/>
        //
        //                     </div>
        //
        //                     <div className="input-container">
        //                         <i className="fa fa-lock icon"/>
        //                         <input className="form-control " id="pass" type="password" name="Password"
        //                                required={true}
        //                                value={this.state.password}
        //                                onChange={this.handlePasswordChange}
        //                                placeholder="Password"/>
        //                     </div>
        //                 </div>
        //
        //                 <i className="fa fa-long-arrow-right" aria-hidden="true"/>
        //                 {
        //                     this.props.loading
        //                         ? <Loader/>
        //                         : <button className="btn btn-lg btn-login btn-block" type="submit">Login</button>
        //                 }
        //                 {validation_error}
        //                 {loginSuccess}
        //
        //             </div>
        //         </form>
        //     </div>
        // </div>;
    }
}


export default LoginPage;
