import React from "react";
import {
    Container, Row,
    Col, Card, CardHeader, CardBody, ListGroup, ListGroupItem, CardFooter, FormSelect
// @ts-ignore
} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import SmallStats from "../../components/common/SmallStats";
import {get_user_logs, get_user_overview, get_user_schedule_logs} from "../../redux/apis/userApis";
import Loader from "../../components/Loader";
import {generate_screen_shot_link, get_schedule_type, get_trigger_type} from "../../redux/types";


export interface ScheduleOverview {
    auto_like_count: number,
    auto_comment_count: number,
    auto_follow_count: number,
    auto_dm_count: number,
    auto_post_count: number,
    auto_unfollow_count: number,
    auto_welcome_dm_count: number,
}
export interface ScheduleStatus {
    pending:number,
    done:number,
    failed:number
}
export interface ScheduleOverview2 {
    auto_like: ScheduleStatus,
    auto_comment: ScheduleStatus,
    auto_follow: ScheduleStatus,
    auto_dm: ScheduleStatus,
    auto_post: ScheduleStatus,
    auto_unfollow: ScheduleStatus,
    auto_welcome_dm: ScheduleStatus,
}

export interface Logger {
    scheduler_id: string,
    scheduler_type: number,
    message: string,
    username: string,
    log_level: number,
    log_time: string,
    screen_shot_id: string,
}

export interface ScheduleLogger {
    id: number,
    link: string,
    error_msg: string,
    error_description: string,
    post_username: string,
    followed_username: string,
    dm_username: string,
    un_followed_username: string,
    processed_user: string,
    un_followed: string,
    username: string,
    create_time: string,
    comment: string,
    message: string,
    status: number,
    trigger: string,
    trigger_type: number,
    screen_shot_id: string,
}

interface UserOverviewProps {
}

interface UserOverviewState {
    username: string,
    smallStats: any[],
    schedule_overview: ScheduleOverview | undefined,
    schedule_overview_loading: boolean,
    schedule_overview_filter: string,
    logs_type_filter_text: string,
    log_time_filter: string,
    log_schedule_type: string,
    logs: Logger[],
    schedule_logs: ScheduleLogger[],
    log_loading: boolean,
}

class UserOverview extends React.Component<UserOverviewProps, UserOverviewState> {


    constructor(props: UserOverviewProps) {
        super(props);
        let username = window.location.pathname.split("/");
        this.state = {
            username: username[2],
            smallStats: [],
            schedule_overview: undefined,
            schedule_overview_loading: true,
            schedule_overview_filter: "7",
            logs_type_filter_text: "logs",
            log_time_filter: "7",
            log_schedule_type: "4",
            logs: [],
            schedule_logs: [],
            log_loading: true,
        };
    }

    load_over_view = () => {
        get_user_overview(this.state.username, this.state.schedule_overview_filter).then(data => {
            if (!data.error) {
                this.setState({
                    schedule_overview: data.overview,
                    schedule_overview_loading: false
                })
            }
        })
    };
    load_log = () => {
        if (this.state.logs_type_filter_text === "logs") {
            get_user_logs(this.state.username, this.state.log_time_filter, this.state.log_schedule_type).then(data => {
                if (!data.error && data.logs) {
                    this.setState({
                        logs: data.logs,

                        schedule_logs: [],
                        log_loading: false
                    })
                } else {
                    this.setState({
                        logs: [],
                        schedule_logs: [],
                        log_loading: false
                    })
                }
            })
        } else {
            console.log("getting user schedule log");
            get_user_schedule_logs(this.state.username,
                this.state.log_time_filter,
                this.state.log_schedule_type).then(data => {
                if (!data.error && data.logs) {
                    this.setState({
                        logs: [],
                        schedule_logs: data.logs,
                        log_loading: false
                    })
                } else {
                    this.setState({
                        logs: [],
                        schedule_logs: [],
                        log_loading: false
                    })
                }
            })
        }
    };
    over_view_on_filter = (e: any) => {
        this.setState({
            schedule_overview_filter: e.target.value,
            schedule_overview_loading: true
        }, this.load_over_view);
    };
    on_filter_type_change = (e: any) => {
        this.setState({
            logs_type_filter_text: e.target.value,
            log_loading: true
        }, this.load_log);
    };
    log_time_change = (e: any) => {
        this.setState({
            log_time_filter: e.target.value,
            log_loading: true
        }, this.load_log);
    };
    log_schedule_type_change = (e: any) => {
        this.setState({
            log_schedule_type: e.target.value,
            log_loading: true
        }, this.load_log);
    };

    componentDidMount(): void {
        this.load_over_view();
        this.load_log();
    }

    getLikeTable = () => {
        return <table className="table mb-0">
            <thead className="bg-light">
            <tr>
                <th scope="col" className="border-0">
                    #
                </th>
                <th scope="col" className="border-0">
                    Post Username
                </th>
                <th scope="col" className="border-0">
                    Create time
                </th>
                <th scope="col" className="border-0">
                    Trigger
                </th>
                <th scope="col" className="border-0">
                    Trigger Type
                </th>
                <th scope="col" className="border-0">
                    Screen-shot
                </th>
            </tr>
            </thead>
            {this.state.log_loading ? <tbody>
            <tr>
                <td colSpan={7}><Loader/></td>
            </tr>
            </tbody> : <tbody>
            {
                this.state.schedule_logs.map((log, index) => {
                    return <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{log.post_username}</td>
                        <td>{log.create_time}</td>
                        <td>{log.trigger}</td>
                        <td>{get_trigger_type(log.trigger_type)}</td>
                        <td>{(log.screen_shot_id === null
                            ||
                            log.screen_shot_id === "null") ?
                            "-" : <a href={generate_screen_shot_link(log.screen_shot_id)} target={"_blank"}>click to
                                see</a>
                        }</td>
                    </tr>;
                })
            }
            </tbody>}
        </table>;
    };
    getCommentTable = () => {
        return <table className="table mb-0">
            <thead className="bg-light">
            <tr>
                <th scope="col" className="border-0">
                    #
                </th>
                <th scope="col" className="border-0">
                    Post link
                </th>
                <th scope="col" className="border-0">
                    Post Username
                </th>
                <th scope="col" className="border-0">
                    Create time
                </th>
                <th scope="col" className="border-0">
                    Comment
                </th>
                <th scope="col" className="border-0">
                    Trigger
                </th>
                <th scope="col" className="border-0">
                    Trigger Type
                </th>
                <th scope="col" className="border-0">
                    Screen-shot
                </th>
            </tr>
            </thead>
            {this.state.log_loading ? <tbody>
            <tr>
                <td colSpan={7}><Loader/></td>
            </tr>
            </tbody> : <tbody>
            {
                this.state.schedule_logs.map((log, index) => {
                    return <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{log.link}</td>
                        <td>{log.post_username}</td>
                        <td>{log.create_time}</td>
                        <td>{log.comment}</td>
                        <td>{log.trigger}</td>
                        <td>{get_trigger_type(log.trigger_type)}</td>
                        <td>{(log.screen_shot_id === null
                            ||
                            log.screen_shot_id === "null") ?
                            "-" : <a href={generate_screen_shot_link(log.screen_shot_id)} target={"_blank"}>click to
                                see</a>
                        }</td>
                    </tr>;
                })
            }
            </tbody>}
        </table>;
    };
    getFollowTable = () => {
        return <table className="table mb-0">
            <thead className="bg-light">
            <tr>
                <th scope="col" className="border-0">
                    id
                </th>
                <th scope="col" className="border-0">
                    Followed Username
                </th>
                <th scope="col" className="border-0">
                    UnFollowed
                </th>
                <th scope="col" className="border-0">
                    Create time
                </th>
                <th scope="col" className="border-0">
                    Trigger
                </th>
                <th scope="col" className="border-0">
                    Trigger Type
                </th>
                <th scope="col" className="border-0">
                    Screen-shot
                </th>
                <th scope="col" className="border-0">
                    Error
                </th>
            </tr>
            </thead>
            {this.state.log_loading ? <tbody>
            <tr>
                <td colSpan={7}><Loader/></td>
            </tr>
            </tbody> : <tbody>
            {
                this.state.schedule_logs.map((log, index) => {
                    return <tr key={index}>
                        <td>{log.id}</td>
                        <td>{log.followed_username}</td>
                        <td>{log.un_followed === null ? "NO" : "Yes"}</td>
                        <td>{log.create_time}</td>
                        <td>{log.trigger}</td>
                        <td>{get_trigger_type(log.trigger_type)}</td>
                        <td>{(log.screen_shot_id === null
                            ||
                            log.screen_shot_id === "null") ?
                            "-" : <a href={generate_screen_shot_link(log.screen_shot_id)} target={"_blank"}>click to
                                see</a>
                        }</td>
                        <td>{log.error_msg !== null ?
                            <div><p>{log.error_msg}</p><p>{log.error_description}</p></div> : ""}</td>

                    </tr>;
                })
            }
            </tbody>}
        </table>;
    };
    getDMTable = () => {
        return <table className="table mb-0">
            <thead className="bg-light">
            <tr>
                <th scope="col" className="border-0">
                    #
                </th>
                <th scope="col" className="border-0">
                    Post Username
                </th>
                <th scope="col" className="border-0">
                    Create time
                </th>
                <th scope="col" className="border-0">
                    Message
                </th>
                <th scope="col" className="border-0">
                    Trigger
                </th>
                <th scope="col" className="border-0">
                    Trigger Type
                </th>
                <th scope="col" className="border-0">
                    Screen-shot
                </th>
            </tr>
            </thead>
            {this.state.log_loading ? <tbody>
            <tr>
                <td colSpan={7}><Loader/></td>
            </tr>
            </tbody> : <tbody>
            {
                this.state.schedule_logs.map((log, index) => {
                    return <tr key={index}>
                        <td>{log.id}</td>
                        <td>{log.processed_user}</td>
                        <td>{log.create_time}</td>
                        <td>{log.comment}</td>
                        <td>{log.trigger}</td>
                        <td>{get_trigger_type(log.trigger_type)}</td>
                        <td>{(log.screen_shot_id === null
                            ||
                            log.screen_shot_id === "null") ?
                            "-" : <a href={generate_screen_shot_link(log.screen_shot_id)} target={"_blank"}>click to
                                see</a>
                        }</td>
                    </tr>;
                })
            }
            </tbody>}
        </table>;
    };
    getWelcomeDMTable = () => {
        return <table className="table mb-0">
            <thead className="bg-light">
            <tr>
                <th scope="col" className="border-0">
                    #
                </th>
                <th scope="col" className="border-0">
                    DM Username
                </th>
                <th scope="col" className="border-0">
                    Create time
                </th>
                <th scope="col" className="border-0">
                    Message
                </th>
                <th scope="col" className="border-0">
                    Screen-shot
                </th>
            </tr>
            </thead>
            {this.state.log_loading ? <tbody>
            <tr>
                <td colSpan={7}><Loader/></td>
            </tr>
            </tbody> : <tbody>
            {
                this.state.schedule_logs.map((log, index) => {
                    return <tr key={index}>
                        <td>{log.id}</td>
                        <td>{log.dm_username}</td>
                        <td>{log.create_time}</td>
                        <td>{log.message}</td>
                        <td>{(log.screen_shot_id === null
                            ||
                            log.screen_shot_id === "null") ?
                            "-" : <a href={generate_screen_shot_link(log.screen_shot_id)} target={"_blank"}>click to
                                see</a>
                        }</td>
                    </tr>;
                })
            }
            </tbody>}
        </table>
    };
    getPostTable = () => {
        return <table className="table mb-0">
            <thead className="bg-light">
            <tr>
                <th scope="col" className="border-0">
                    #
                </th>
                <th scope="col" className="border-0">
                    Log time
                </th>
                <th scope="col" className="border-0">
                    Message
                </th>
                <th scope="col" className="border-0">
                    Schedule Type
                </th>
                <th scope="col" className="border-0">
                    Screen Shot
                </th>
            </tr>
            </thead>
            {this.state.log_loading ? <tbody>
            <tr>
                <td colSpan={7}><Loader/></td>
            </tr>
            </tbody> : <tbody>
            {
                this.state.logs.map((log, index) => {
                    return <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{log.log_time}</td>
                        <td>{log.message}</td>
                        <td>{get_schedule_type(log.scheduler_type)}</td>
                        <td>{(log.screen_shot_id === null
                            ||
                            log.screen_shot_id === "null") ?
                            "-" : <a href={generate_screen_shot_link(log.screen_shot_id)} target={"_blank"}>click to
                                see</a>
                        }</td>
                    </tr>;
                })
            }
            </tbody>}
        </table>
    };
    getUnfollowTable = () => {
        return <table className="table mb-0">
            <thead className="bg-light">
            <tr>
                <th scope="col" className="border-0">
                    id
                </th>
                <th scope="col" className="border-0">
                    UnFollowed Username
                </th>
                <th scope="col" className="border-0">
                    Create time
                </th>
                <th scope="col" className="border-0">
                    Screen-shot
                </th>
                <th scope="col" className="border-0">
                    Error
                </th>

            </tr>
            </thead>
            {this.state.log_loading ? <tbody>
            <tr>
                <td colSpan={7}><Loader/></td>
            </tr>
            </tbody> : <tbody>
            {
                this.state.schedule_logs.map((log, index) => {
                    return <tr key={index}>
                        <td>{log.id}</td>
                        <td>{log.un_followed_username}</td>
                        <td>{log.create_time}</td>
                        <td>{(log.screen_shot_id === null
                            ||
                            log.screen_shot_id === "null") ?
                            "-" : <a href={generate_screen_shot_link(log.screen_shot_id)} target={"_blank"}>click to
                                see</a>
                        }</td>
                        <td>{log.error_msg !== null ?
                            <div><p>{log.error_msg}</p><p>{log.error_description}</p></div> : ""}</td>
                    </tr>;
                })
            }
            </tbody>}
        </table>;
    };
    getScheduleTable = () => {
        if (this.state.log_schedule_type === "2") {
            return this.getLikeTable();
        } else if (this.state.log_schedule_type === "3") {
            return this.getCommentTable();
        } else if (this.state.log_schedule_type === "4") {
            return this.getFollowTable();
        } else if (this.state.log_schedule_type === "5") {
            return this.getDMTable();
        } else if (this.state.log_schedule_type === "6") {
            return this.getWelcomeDMTable();
        } else if (this.state.log_schedule_type === "7") {
            return this.getPostTable();
        } else if (this.state.log_schedule_type === "8") {
            return this.getUnfollowTable();
        }
    };

    render() {
        let table = this.state.logs_type_filter_text === "logs" ?
            <table className="table mb-0">
                <thead className="bg-light">
                <tr>
                    <th scope="col" className="border-0">
                        #
                    </th>
                    <th scope="col" className="border-0">
                        Log time
                    </th>
                    <th scope="col" className="border-0">
                        Message
                    </th>
                    <th scope="col" className="border-0">
                        Schedule Type
                    </th>
                    <th scope="col" className="border-0">
                        Screen Shot
                    </th>
                </tr>
                </thead>
                {this.state.log_loading ? <tbody>
                <tr>
                    <td colSpan={7}><Loader/></td>
                </tr>
                </tbody> : <tbody>
                {
                    this.state.logs.map((log, index) => {
                        return <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{log.log_time}</td>
                            <td>{log.message}</td>
                            <td>{get_schedule_type(log.scheduler_type)}</td>
                            <td>{(log.screen_shot_id === null
                                ||
                                log.screen_shot_id === "null") ?
                                "-" : <a href={generate_screen_shot_link(log.screen_shot_id)} target={"_blank"}>click to
                                    see</a>
                            }</td>
                        </tr>;
                    })
                }
                </tbody>}
            </table> : this.getScheduleTable()
        ;
        return <Container fluid className="main-content-container px-4">

            <Row noGutters className="page-header py-4">
                <PageTitle title={this.state.username} subtitle="Dashboard" className="text-sm-left mb-3"/>
            </Row>
            <Row>
                {this.state.smallStats.map((stats, idx) => (
                    <Col className="col-lg mb-4" key={idx} md={"6"} sm={"6"}>
                        <SmallStats
                            variation="1"
                            label={stats.label}
                            value={stats.value}
                            percentage={stats.percentage}
                            increase={stats.increase}
                        />
                    </Col>
                ))}
                <Col lg="3" md="12" sm="12" className="mb-4">
                    <Card small>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Overview</h6>
                            <div className="block-handle"/>
                        </CardHeader>
                        {this.state.schedule_overview_loading ? <Loader/> : <CardBody className="p-0">
                            <ListGroup small flush className="list-group-small">
                                <ListGroupItem className="d-flex px-3">
                                    <span className="text-semibold text-fiord-blue">Auto like</span>
                                    <span className="ml-auto text-right text-semibold text-reagent-gray">
              {this.state.schedule_overview ? this.state.schedule_overview.auto_like_count : 0}
            </span>
                                </ListGroupItem>
                                <ListGroupItem className="d-flex px-3">
                                    <span className="text-semibold text-fiord-blue">Auto comment</span>
                                    <span className="ml-auto text-right text-semibold text-reagent-gray">
              {this.state.schedule_overview ? this.state.schedule_overview.auto_comment_count : 0}
            </span>
                                </ListGroupItem>
                                <ListGroupItem className="d-flex px-3">
                                    <span className="text-semibold text-fiord-blue">Auto follow</span>
                                    <span className="ml-auto text-right text-semibold text-reagent-gray">
              {this.state.schedule_overview ? this.state.schedule_overview.auto_follow_count : 0}
            </span>
                                </ListGroupItem>
                                <ListGroupItem className="d-flex px-3">
                                    <span className="text-semibold text-fiord-blue">Auto dm</span>
                                    <span className="ml-auto text-right text-semibold text-reagent-gray">
              {this.state.schedule_overview ? this.state.schedule_overview.auto_dm_count : 0}
            </span>
                                </ListGroupItem>
                                <ListGroupItem className="d-flex px-3">
                                    <span className="text-semibold text-fiord-blue">Auto post</span>
                                    <span className="ml-auto text-right text-semibold text-reagent-gray">
              {this.state.schedule_overview ? this.state.schedule_overview.auto_post_count : 0}
            </span>
                                </ListGroupItem>
                                <ListGroupItem className="d-flex px-3">
                                    <span className="text-semibold text-fiord-blue">Auto UnFollow</span>
                                    <span className="ml-auto text-right text-semibold text-reagent-gray">
              {this.state.schedule_overview ? this.state.schedule_overview.auto_unfollow_count : 0}
            </span>
                                </ListGroupItem>
                                <ListGroupItem className="d-flex px-3">
                                    <span className="text-semibold text-fiord-blue">Auto Welcome DM</span>
                                    <span className="ml-auto text-right text-semibold text-reagent-gray">
              {this.state.schedule_overview ? this.state.schedule_overview.auto_welcome_dm_count : 0}
            </span>
                                </ListGroupItem>
                            </ListGroup>
                        </CardBody>}
                        {this.state.schedule_overview_loading ? <div/> : <CardFooter className="border-top">
                            <Row>
                                <Col>
                                    <FormSelect
                                        size="sm"
                                        value={this.state.schedule_overview_filter}
                                        style={{maxWidth: "130px"}}
                                        onChange={this.over_view_on_filter}
                                    >
                                        <option value="1">Last 1 day</option>
                                        <option value="2">Last 2 day</option>
                                        <option value="3">Last 3 day</option>
                                        <option value="7">Last 7 day</option>
                                        <option value="30">Last 30 day</option>
                                        <option value="365">Last 365 day</option>
                                    </FormSelect>
                                </Col>
                            </Row>
                        </CardFooter>}
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card small className="mb-4 overflow-scroll">
                        <CardHeader className="border-bottom">
                            <Row>
                                <Col><h6 className="m-0">Logs</h6></Col>
                                <Col> <FormSelect
                                    size="sm"
                                    value={this.state.logs_type_filter_text}
                                    style={{maxWidth: "130px"}}
                                    onChange={this.on_filter_type_change}
                                >
                                    <option value="logs">Logs</option>
                                    <option value="scheduler_logs">Scheduler logs</option>
                                </FormSelect></Col>
                                <Col> <FormSelect
                                    size="sm"
                                    value={this.state.log_time_filter}
                                    style={{maxWidth: "130px"}}
                                    onChange={this.log_time_change}
                                >
                                    <option value="1">Last 1 day</option>
                                    <option value="2">Last 2 day</option>
                                    <option value="3">Last 3 day</option>
                                    <option value="7">Last 7 day</option>
                                    <option value="30">Last 30 day</option>
                                    <option value="365">Last 365 day</option>
                                </FormSelect> </Col>
                                <Col className="w-100 col-lg mb-4" md={"1"} sm={"1"}>
                                    <FormSelect
                                        size="sm"
                                        value={this.state.log_schedule_type}
                                        style={{maxWidth: "130px"}}
                                        onChange={this.log_schedule_type_change}
                                    >
                                        {this.state.logs_type_filter_text === "logs" ?
                                            <option value="1">Auto Login</option> : null}
                                        {this.state.logs_type_filter_text === "logs" ?
                                            <option value="9">Common</option> : null}
                                        <option value="2">Auto Like</option>
                                        <option value="3">Auto Comment</option>
                                        <option value="4">Auto Follow</option>
                                        <option value="5">Auto DM</option>
                                        <option value="6">Auto Welcome DM</option>
                                        <option value="7">Auto Post</option>
                                        <option value="8">Auto UnFollow</option>
                                    </FormSelect>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0 pb-3">
                            {table}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }
}


export default UserOverview;
