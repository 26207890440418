import axios from 'axios';
import {SERVER_ADDRESS} from "../constants";
import {iApiBasicResponse, IScheduleOverview, IUserLog, IUserOverview, IUserScheduleLog, IUsersData} from "../types";

export interface iLoginResponse extends iApiBasicResponse {
    name: string
    email: string
}

export const login = (email: string, password: string): Promise<iLoginResponse> => {
    let fd = new FormData();
    fd.set("username", email);
    fd.set("password", password);
    return axios
        .post(SERVER_ADDRESS + "/admin/login", fd, {
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            withCredentials: true,
        })
        .then((res): iLoginResponse => {
            return res.data;
        }).catch((): iLoginResponse => {
            return {message: "Network Error", error: true, name: "", email: ""}
        });
};

export const logout = (): Promise<iApiBasicResponse> => {
    return axios
        .get(SERVER_ADDRESS + "/admin/logout", {
            withCredentials: true,
        })
        .then(res => {
            return res.data;
        }).catch((): iApiBasicResponse => {
            return {message: "Network Error", error: true}
        });
};

export const verify = (): Promise<iLoginResponse> => {
    return axios
        .get(SERVER_ADDRESS + "/admin/verify", {
            withCredentials: true,
        })
        .then(res => {
            return res.data;
        }).catch((): iLoginResponse => {
            return {message: "Network Error", error: true, name: "", email: ""}
        });
};
export const get_all_users = (): Promise<IUsersData> => {
    let fd = new FormData();

    return axios
        .post(SERVER_ADDRESS + "/admin/all-users", fd, {
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            withCredentials: true,
        })
        .then((res): IUsersData => {
            return res.data;
        }).catch((): IUsersData => {
            return {message: "Network Error", error: true}
        });
};

export const get_user_overview = (username: string, filter_time: string): Promise<IUserOverview> => {
    let fd = new FormData();
    fd.set("username", username);
    fd.set("filter_time", filter_time);

    return axios
        .post(SERVER_ADDRESS + "/admin/user-overview", fd, {
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            withCredentials: true,
        })
        .then((res): IUserOverview => {

            return res.data;
        }).catch((): IUserOverview => {
            return {message: "Network Error", error: true}
        });
};
export const get_user_logs = (username: string, filter_time: string, scheduler_type: string): Promise<IUserLog> => {
    let fd = new FormData();
    fd.set("username", username);
    fd.set("filter_time", filter_time);
    fd.set("scheduler_type", scheduler_type);

    return axios
        .post(SERVER_ADDRESS + "/admin/user-log", fd, {
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            withCredentials: true,
        })
        .then((res): IUserLog => {
            return res.data;
        }).catch((): IUserLog => {
            return {message: "Network Error", error: true}
        });
};

export const get_user_schedule_logs = (username: string,
                                       filter_time: string,
                                       scheduler_type: string): Promise<IUserScheduleLog> => {
    let fd = new FormData();
    fd.set("username", username);
    fd.set("filter_time", filter_time);
    fd.set("scheduler_type", scheduler_type);

    return axios
        .post(SERVER_ADDRESS + "/admin/user-scheduler-log", fd, {
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            withCredentials: true,
        })
        .then((res): IUserScheduleLog => {
            return res.data;
        }).catch((): IUserScheduleLog => {
            return {message: "Network Error", error: true}
        });
};

export const get_overview = (filter_time: string): Promise<IUserOverview> => {
    let fd = new FormData();
    fd.set("filter_time", filter_time);

    return axios
        .post(SERVER_ADDRESS + "/admin/overview", fd, {
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            withCredentials: true,
        })
        .then((res): IUserOverview => {

            return res.data;
        }).catch((): IUserOverview => {
            return {message: "Network Error", error: true}
        });
};

export const get_schedule_overview = (filter_time: string, schedule_type: string): Promise<IScheduleOverview> => {
    let fd = new FormData();
    fd.set("filter_time", filter_time);
    fd.set("scheduler_type", schedule_type);

    return axios
        .post(SERVER_ADDRESS + "/admin/schedules-overview", fd, {
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            withCredentials: true,
        })
        .then((res): IScheduleOverview => {

            return res.data;
        }).catch((): IScheduleOverview => {
            // @ts-ignore
            return {message: "Network Error", error: true}
        });
};
export const delete_user = (username: string): Promise<any> => {
    let fd = new FormData();
    fd.set("username", username);

    return axios
        .post(SERVER_ADDRESS + "/admin/delete_user", fd, {
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            withCredentials: true,
        })
        .then((res): any => {
        }).catch((): any => {
        });
};
