import React from "react";
// @ts-ignore
import {Nav} from "shards-react";

import SidebarNavItem from "./SidebarNavItem";

interface SidebarNavItemsProps {

}

interface SidebarNavItemsState {
    navItems: any[]
}

class SidebarNavItems extends React.Component<SidebarNavItemsProps, SidebarNavItemsState> {
    constructor(props: SidebarNavItemsProps) {
        super(props);
        let navItems = [
            {
                title: "Overview",
                to: "/overview",
                htmlBefore: '<i class="material-icons">dashboard</i>',
                htmlAfter: ""
            },
            {
                title: "Users",
                to: "/users",
                htmlBefore: '<i class="material-icons">person</i>',
                htmlAfter: ""
            }
        ];
        this.state = {
            navItems: navItems
        };

    }

    onChange = () => {

    };

    render() {
        const {navItems: items} = this.state;
        return (
            <div className="nav-wrapper">
                <Nav className="nav--no-borders flex-column">
                    {items.map((item, idx) => (
                        <SidebarNavItem key={idx} item={item}/>
                    ))}
                </Nav>
            </div>
        )
    }
}

export default SidebarNavItems;
