import React from "react";
// @ts-ignore
import {Card, CardBody, CardFooter, CardHeader, Col, FormSelect, Row} from "shards-react";

interface ScheduleOverviewProps {
    title?: string,
    canvas_ref?: any
    filter_value: any
    onFormChange(e:any):any
}

class ScheduleOverviewComponent extends React.Component<ScheduleOverviewProps> {
    render() {
        return <Card small className="h-100">
            <CardHeader className="border-bottom">
                <h6 className="m-0">{this.props.title}</h6>
            </CardHeader>
            <CardBody className="d-flex py-0">
                <canvas
                    height="220"
                    ref={this.props.canvas_ref}
                    className="blog-users-by-device m-auto"
                />
            </CardBody>
            <CardFooter className="border-top">
                <Row>
                    <Col>
                        <FormSelect
                            size="sm"
                            value={this.props.filter_value}
                            style={{maxWidth: "130px"}}
                            onChange={this.props.onFormChange}
                        >
                            <option value="1">Last 1 day</option>
                            <option value="2">Last 2 day</option>
                            <option value="3">Last 3 day</option>
                            <option value="7">Last 7 day</option>
                            <option value="30">Last 30 day</option>
                            <option value="365">Last 365 day</option>
                        </FormSelect>
                    </Col>
                </Row>
            </CardFooter>
        </Card>
    }
}
export default ScheduleOverviewComponent;