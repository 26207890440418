import React from "react";

import "./style.css";

export interface LoaderProps {

}

class Loader extends React.Component<LoaderProps> {
    render() {
        // noinspection CheckTagEmptyBody
        return <div style={{width:20,margin:"auto"}}><div className="lds-css ng-scope">
            <div className="lds-rolling">
                <div></div>
            </div>
        </div></div>;
    }
}

export default Loader;