import React from "react";
import classNames from "classnames";
// @ts-ignore
import { Container, Navbar } from "shards-react";

import NavbarSearch from "./NavbarSearch";
import NavbarNav from "./NavbarNav/NavbarNav";
import NavbarToggle from "./NavbarToggle";

interface MainNavbarProps {
  layout:string,
  stickyTop:boolean
}
class MainNavbar extends React.Component<MainNavbarProps>{
  static defaultProps = {
    layout: "",
    stickyTop:false
  };
  render(){
    const classes = classNames(
        "main-navbar",
        "bg-white",
        this.props.stickyTop && "sticky-top"
    );

    return (
        <div className={classes}>
          <Container className="p-0">
            <Navbar type="light" className="align-items-stretch flex-md-nowrap p-0">
              <NavbarSearch />
              <NavbarNav />
              <NavbarToggle />
            </Navbar>
          </Container>
        </div>
    );
  }
}


export default MainNavbar;
