import {iStoreAction} from '../actions';
import {getInitialStoreState, iStoreState} from '../types';
import * as constants from '../constants';
import {Reducer} from "redux";

export const storeReducer: Reducer<iStoreState, iStoreAction> = (storeState, action): iStoreState => {

    // Store may be undefined in the beginning
    if (!storeState) return getInitialStoreState();

    // Perform actions
    switch (action.type) {
        case constants.SET_LOGGED_IN_USER:
            return {...storeState, loggedInUser: action.user};
        case constants.REMOVE_LOGGED_IN_USER:
            return {...storeState, loggedInUser: undefined}
    }
    return storeState;
};