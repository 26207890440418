import React, {Dispatch} from "react";
import {Link} from "react-router-dom";
// @ts-ignore
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, NavItem, NavLink} from "shards-react";
import {iApiBasicResponse, iStoreState} from "../../../../redux/types";
import {iStoreAction, removeLoggedInUser} from "../../../../redux/actions";
import {connect} from "react-redux";
import {logout} from "../../../../redux/apis/userApis";

interface UserActionsProps {

    loggedInUserName: string;
    loggedInUserEmail: string;
    dispatch: Dispatch<iStoreAction>;
}

interface UserActionsStates {
    visible: boolean
}
class UserActions extends React.Component<UserActionsProps, UserActionsStates> {
    constructor(props: UserActionsProps) {
        super(props);

        this.state = {
            visible: false
        };

        this.toggleUserActions = this.toggleUserActions.bind(this);
    }

    toggleUserActions() {
        this.setState({
            visible: !this.state.visible
        });
    }
    onLogoutResponse = (response: iApiBasicResponse,) => {
        if (!response.error) {
            this.props.dispatch(removeLoggedInUser());
        }
    };
    onClickLogout = () => {
        logout().then(response => this.onLogoutResponse(response));
    };

    render() {
        return (
            <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
                <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
                    <img
                        className="user-avatar rounded-circle mr-2"
                        src={require("./../../../../images/avatars/2.jpg")}
                        alt="User Avatar"
                    />{" "}
                    <span className="d-none d-md-inline-block">Admin</span>
                </DropdownToggle>
                <Collapse tag={DropdownMenu} right small open={this.state.visible}>
                    <DropdownItem divider/>
                    <DropdownItem tag={Link} to="/" onClick={this.onClickLogout} className="text-danger">
                        <i className="material-icons text-danger">&#xE879;</i> Logout
                    </DropdownItem>
                </Collapse>
            </NavItem>
        );
    }
}
function mapStateToProps({loggedInUser}: iStoreState) {
    return {
        loggedInUserName: loggedInUser ? loggedInUser.name : "",
        loggedInUserEmail: loggedInUser ? loggedInUser.email : ""
    }
}

function mapDispatchToProps(dispatch: Dispatch<iStoreAction>) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserActions);
