import React from "react";
// @ts-ignore
import {Container, Row, Nav, NavItem, NavLink} from "shards-react";
import {Link} from "react-router-dom";

interface MenuItemProps {
    title: String,
    to: String
}

interface MainFooterProps {
    contained: Boolean,
    menuItems: MenuItemProps[],
    copyright: String
}

class MainFooter extends React.Component<MainFooterProps> {
    static defaultProps = {
      contained: false,
      menuItems: [],
      copyright:"some copy right"
    };

    render() {
        return <footer className="main-footer d-flex p-2 px-3 bg-white border-top">
            <Container fluid={this.props.contained}>
                <Row>
                    <Nav>
                        {this.props.menuItems.map((item, idx) => (
                            <NavItem key={idx}>
                                <NavLink tag={Link} to={item.to}>
                                    {item.title}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                    <span className="copyright ml-auto my-auto mr-2">{this.props.copyright}</span>
                </Row>
            </Container>
        </footer>;
    }
}

export default MainFooter;
